<template>
  <v-container
    :id="`${displayAsPlural.toLowerCase().replaceAll(' ', '-')}-view`"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <!-- Overlay is used to display spinner while page is loading -->
        <v-overlay :value="importData.isBusy">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-overlay>

        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>{{ appItem.title }}</h3>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container
              class="py-0 pb-5"
            >
              <!-- Select Customer & Import Type -->
              <v-row
                v-if="customers.data.length > 1"
                no-gutters
                align="center"
              >
                <!-- Select Customer -->
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                >
                  <select-customer
                    id="2-maintain-customer"
                    v-model="customers.selectedItem"
                    :appendhint="`to ${displayAsSingular} Data Into`"
                  />
                </v-col>
                <!-- Import Type -->
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                  offset-md="1"
                >
                  <combo-box
                    ref="ImportType"
                    v-model="importData.selectedImportType"
                    :items="importData.importTypes"
                    :label="`${displayAsSingular} Type`"
                    :displayAsSingular="`${displayAsSingular} Type`"
                    :required="true"
                    @change="importType_onChange"
                  />
                </v-col>
              </v-row>
              <!-- File to Import -->
              <v-row
                v-if="displayFileUpload"
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <v-file-input
                    v-model="importFile"
                    show-size
                    :label="`Data to ${displayAsSingular}`"
                    truncate-length="50"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="setupImportFile"
                  />
                </v-col>
              </v-row>
              <!-- Detected Sheets -->
              <v-row
                v-if="displaySheets"
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <multi-select
                    id="ImportSheetNames"
                    v-model="importData.selected"
                    :items="importData.sheetNames"
                    item-text="sheetName"
                    item-value="sheetName"
                    :label="`Sheets to ${displayAsSingular}`"
                  />
                </v-col>
              </v-row>
              <!-- Selected Sheets to Import -->
              <v-row
                v-if="displaySheets && importData.selected.length > 0"
                no-gutters
              >
                <v-col
                  cols="8"
                  class="text-left"
                >
                  <v-list dense>
                    <v-subheader>
                      <h2
                        class="primary--text"
                      >
                        Selected Sheets to Import
                      </h2>
                    </v-subheader>
                    <v-list-item-group
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in importData.selected"
                        :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="item.icon" />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <div style="display: inline;">
                            {{ item.sheetName }} - <span class="secondary--text">{{ item.sheetRows }}</span>
                            {{ (item.sheetRows == 1 ? 'Row' : 'Rows') }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>

                <v-col
                  cols="4"
                  class="text-left"
                >
                  <v-btn
                    small
                    color="primary"
                    class="ml-0 mt-1 mr-4 mb-0 pt-4 pb-4"
                    @click="executeImport_onClick"
                  >
                    Execute {{ displayAsSingular }}
                  </v-btn>
                </v-col>
              </v-row>
              <!-- Preview Table -->
              <v-row
                v-if="importData.data.length > 0"
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="12"
                >
                  <preview-import-table
                    ref="previewImportTable"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>

        <!-- Dialogs -->
        <confirmation-dialog
          ref="importConfirmationDialog"
          @confirm="processImport"
        >
          <template #title>
            <h2 class="primary--text">
              Confirm {{ displayAsSingular }}
            </h2>
          </template>

          <template #message>
            <div class="text-left">
              Are you sure you want to {{ displayAsSingular.toLowerCase() }} the following data into the
              <span class="primary--text">{{ customers.selectedItem.description }}</span>
              Customer?
              <v-list dense>
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in importData.selected"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div style="display: inline;">
                        {{ item.sheetName }} - <span class="secondary--text">{{ item.sheetRows }}</span>
                        {{ (item.sheetRows == 1 ? 'Row' : 'Rows') }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </template>
        </confirmation-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import XLSX from 'xlsx'
  import { get } from 'vuex-pathify'
  import MaterialCard from '@/components/MaterialCard'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import ComboBox from '@/components/maintenance/controls/ComboBox'
  import MultiSelect from '@/components/maintenance/controls/MultiSelect'
  import PreviewImportTable from '@/components/maintenance/tables/PreviewImportTable'
  import ConfirmationDialog from '@/components/maintenance/ConfirmationDialog'

  export default {
    name: 'ImportsView',

    components: {
      MaterialCard,
      SelectCustomer,
      ComboBox,
      MultiSelect,
      PreviewImportTable,
      ConfirmationDialog,
    },

    data () {
      return {
        appItem: null,
        displayFileUpload: false,
        displaySheets: false,
        importFile: null,
        parts2Import: [],
        prompts2Import: [],
        divisions2Import: [],
        assemblies2Import: [],
        assemblyItems2Import: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
        'getDataById',
      ]),
      customers: get('customer/customers'),
      importData: get('importData/imports'),
      displayAsSingular: get('importData/displayAsSingular'),
      displayAsPlural: get('importData/displayAsPlural'),
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        let parent2Import

        switch (mutation.type) {
          case 'importData/successfulImport':
            this.importData.selectedImportType = null
            this.importFile = null

            break
          case 'category/importCompleted':
            if (this.parts2Import.length > 0) {
              this.$store.dispatch('importData/executeImport', this.parts2Import[0])
            } else {
              this.verifyImportCompletion()
            }

            break
          case 'promptgroup/push2Data':
            // When Prompt Groups import is complete AND the new Prompt Groups have been retrieved, now we can import Prompts
            parent2Import = this.importData.selected.filter(data => data.sheetName === 'Prompt Groups')

            if (parent2Import) {
              if (parent2Import.length > 0) {
                if ({}.hasOwnProperty.call(parent2Import[0], 'isComplete')) {
                  if (parent2Import[0].isComplete && this.prompts2Import.length > 0) {
                    this.$store.dispatch('importData/executeImport', this.prompts2Import[0])
                  } else {
                    this.verifyImportCompletion()
                  }
                }
              }
            }

            break
          case 'plan/importCompleted':
            if (this.divisions2Import.length > 0) {
              this.$store.dispatch('importData/executeImport', this.divisions2Import[0])
            } else {
              this.verifyImportCompletion()
            }

            break
          case 'division/importCompleted':
            if (this.assemblies2Import.length > 0) {
              this.$store.dispatch('importData/executeImport', this.assemblies2Import[0])
            } else {
              this.verifyImportCompletion()
            }

            break
          case 'assembly/importCompleted':
            if (this.assemblyItems2Import.length > 0) {
              this.$store.dispatch('importData/executeImport', this.assemblyItems2Import[0])
            } else {
              this.verifyImportCompletion()
            }

            break
          case 'promptgroup/importCompleted':
          case 'part/importCompleted':
          case 'deliveryload/importCompleted':
          case 'usage/importCompleted':
          case 'prompt/importCompleted':
          case 'assemblyitem/importCompleted':
            this.verifyImportCompletion()
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      initialize () {
        this.appItem = this.getAppItem(this.$options.name)
        this.$store.dispatch('user/setPageName', this.$options.name)
      },

      importType_onChange () {
        this.$store.dispatch('importData/setEmpty')
        this.importFile = null

        if (this.importData.selectedImportType) {
          this.displayFileUpload = true

          if (this.importData.selectedImportType.id === 'Tabbed-Spreadsheet-Template') {
            this.displaySheets = true
          } else {
            this.displaySheets = false
          }
        } else {
          this.displayFileUpload = false
          this.displaySheets = false
        }
      },

      async setupImportFile (file2Import) {
        const workbook2Import = await this.readFileAsync(file2Import)

        this.$store.dispatch('importData/setup', workbook2Import)
      },

      readFileAsync (file) {
        this.$store.dispatch('importData/setIsBusy', true)

        return new Promise((resolve, reject) => {
          const reader = new FileReader()

          FileReader.prototype.readAsBinaryString = function (file) {
            let binary = ''
            let wb

            reader.onload = function (e) {
              const bytes = new Uint8Array(reader.result)
              const length = bytes.byteLength
              for (let i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i])
              }
              wb = XLSX.read(binary, {
                type: 'binary',
              })

              resolve(wb)
            }
            reader.readAsArrayBuffer(file)
          }
          reader.onerror = reject

          reader.readAsBinaryString(file)
        })
      },

      processImport () {
        let parent2Import

        // Initialize 2Import vars so as not to remember a previous run
        this.parts2Import = []
        this.prompts2Import = []
        this.divisions2Import = []
        this.assemblies2Import = []
        this.assemblyItems2Import = []

        for (const currentData of this.importData.selected) {
          // Determine parent/child relationship and only import the children when the parents are done importing
          switch (currentData.sheetName) {
            case 'Parts':
              parent2Import = this.importData.selected.filter(data => data.sheetName === 'Categories')

              if (parent2Import.length > 0) {
                // Categories ARE being imported this round
                // So we need to wait until they are done before we can import Parts
                this.parts2Import = [currentData]
              } else {
                // Categories are NOT being imported this round
                // So we can proceed with importing Parts
                this.$store.dispatch('importData/executeImport', currentData)
              }

              break
            case 'Prompts':
              parent2Import = this.importData.selected.filter(data => data.sheetName === 'Prompt Groups')

              if (parent2Import.length > 0) {
                // Prompt Groups ARE being imported this round
                // So we need to wait until they are done before we can import Prompts
                this.prompts2Import = [currentData]
              } else {
                // Prompt Groups are NOT being imported this round
                // So we can proceed with importing Prompts
                this.$store.dispatch('importData/executeImport', currentData)
              }

              break
            case 'Divisions':
              parent2Import = this.importData.selected.filter(data => data.sheetName === 'Plans')

              if (parent2Import.length > 0) {
                // Plans ARE being imported this round
                // So we need to wait until they are done before we can import Divisions
                this.divisions2Import = [currentData]
              } else {
                // Plans are NOT being imported this round
                // So we can proceed with importing Divisions
                this.$store.dispatch('importData/executeImport', currentData)
              }

              break
            case 'Assemblies':
              parent2Import = this.importData.selected.filter(data => data.sheetName === 'Divisions')

              if (parent2Import.length > 0) {
                // Divisions ARE being imported this round
                // So we need to wait until they are done before we can import Assemblies
                this.assemblies2Import = [currentData]
              } else {
                // Divisions are NOT being imported this round
                // So we can proceed with importing Assemblies
                this.$store.dispatch('importData/executeImport', currentData)
              }

              break
            case 'Assembly Items':
              parent2Import = this.importData.selected.filter(data => data.sheetName === 'Assemblies')

              if (parent2Import.length > 0) {
                // Assemblies ARE being imported this round
                // So we need to wait until they are done before we can import Assembly Items
                this.assemblyItems2Import = [currentData]
              } else {
                // Assemblies are NOT being imported this round
                // So we can proceed with importing Assembly Items
                this.$store.dispatch('importData/executeImport', currentData)
              }

              break
            default:
              this.$store.dispatch('importData/executeImport', currentData)
              break
          }
        }
      },

      executeImport_onClick () {
        this.$refs.importConfirmationDialog.setDisplayDialog(true)
      },

      verifyImportCompletion () {
        let completeImport = true

        if (this.importData.selectedImportType.id === 'Tabbed-Spreadsheet-Template') {
          for (const currentData of this.importData.selected) {
            if (completeImport && currentData.isComplete) {
              completeImport = true
            } else {
              completeImport = false
              break
            }
          }
        }

        if (completeImport) {
          this.$store.dispatch('importData/importCompleted')
        }
      },
    },
  }
</script>
